<template>
  <section class="attend">
    <div class="attend__content">
      <div class="attend__content--images">
        <div class="single-thumbnail">
          <img src="@/assets/hotel/hotel-1.jpeg" alt="Condado Vanderbilt Hotel">
        </div>
        <div class="multiple-thumbnails">
          <img src="@/assets/hotel/hotel-2.jpeg" alt="Condado Vanderbilt Hotel Bridge">
          <img src="@/assets/hotel/hotel-18.jpeg" alt="Condado Vanderbilt Hotel Business Center">
        </div>
      </div>
      <div class="attend__content--info">
        <h1 class="attend__title">
          Why Attend?
        </h1>
        <p class="attend__description">
          Prepare yourself for an unparalleled experience where cutting-edge innovation,
          high-powered networking, and incredible investment opportunities coalesce.
          This 2024 edition is hosted at the opulent Condado Vanderbilt Hotel in San Juan, Puerto Rico. SRAX is ecstatic to present:
        </p>
        <div class="attend__highlights">
          <div class="attend__highlights--single">
            <img src="@/assets/evmobility/icons/micro.svg" alt="Microphone Icon">
            <p class="attend__description">
              Stellar line-up of industry speakers
            </p>
          </div>
          <div class="attend__highlights--single">
            <img src="@/assets/evmobility/icons/hotel.svg" alt="Building Icon">
            <p class="attend__description">
              75+ companies presenting their vision
            </p>
          </div>
          <div class="attend__highlights--single">
            <img src="@/assets/evmobility/icons/user.svg" alt="User Icon">
            <p class="attend__description">
              Over 500 attends, including investors, funds, family offices, and analysts
            </p>
          </div>
        </div>
        <generalformcta button-text="Event Registration" />
      </div>
    </div>
  </section>
</template>


<script>
import generalformcta from '@/components/generalformcta.vue';

export default {
  name: 'EV attend',
  components: {
    generalformcta,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  @import "@/styles/components/evmobility/evattend.scss";
  .attend {
    &__content {
      .formcta {
        background-color: transparent;
        margin-top: 40px;
        &__content {
          position: relative;
          bottom: 0;
          display: flex;
        }
        &__btn {
          padding: 7px 40px;
          color: $sequire-dark;
          &:hover {
            color: $yellow;
          }
        }
      }
    }
  }
</style>