<template>
  <section class="hero">
      <div class="hero__content container">
        <div class="hero__content--headline">
          <img class="ev-logo" src="@/assets/evmobility/ev-logo.svg">
          <h1 class="hero__title">
            Sequire and EV Mobility Invite you to the Second  Annual 
            Investor Summit in Puerto Rico on a Private Jet.
          </h1>
          <h2 class="hero__subtitle">
            January 23-25, 2024
          </h2>
        </div>
      </div>
      <div class="hero__content--slider">
        <Carousel :items-to-show="computedItemsToShow" :wrap-around="true" v-model="currentSlide">
          <Slide v-for="(image, index) in images" :key="index">
            <div class="carousel__item">
              <img :src="image" :alt="'Image ' + index">
            </div>
          </Slide>
          <template #addons>
            <Pagination />
            <Navigation />
          </template>
        </Carousel>
      </div>
      <div class="hero__content--info container">
        <h3 class="hero__description">
          Brought to you by EV Mobility. Take luxury to new heights—literally!
          Secure your seat on a private jet, bound for Sequire's Investor Summit in Puerto Rico. <br>
          Departure options include:
        </h3>
        <div class="hero__locations">
          <h3 class="hero__locations--city">
            Los Angeles
          </h3>
          <h3 class="hero__locations--city">
            New York
          </h3>
          <h3 class="hero__locations--city">
            Chicago
          </h3>
          <h3 class="hero__locations--city">
            Miami
          </h3>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import { defineComponent } from 'vue';
  import { Carousel, Navigation, Slide, Pagination } from 'vue3-carousel';
  import 'vue3-carousel/dist/carousel.css';

  export default defineComponent({
    name: 'EV Hero',
    data() {
      return {
        images: [
          require('@/assets/evmobility/aircraft/air-1.webp'),
          require('@/assets/evmobility/aircraft/air-3.jpeg'),
          require('@/assets/evmobility/aircraft/air-4.jpeg'),
          require('@/assets/evmobility/aircraft/air-5.jpeg'),
          require('@/assets/evmobility/aircraft/air-6.jpeg'),
          require('@/assets/evmobility/aircraft/air-7.jpeg'),
          require('@/assets/evmobility/aircraft/air-8.jpeg'),
          require('@/assets/evmobility/aircraft/air-9.jpeg')
        ],
        currentSlide: 0,
      };
    },
    components: {
      Carousel,
      Slide,
      Navigation,
      Pagination
    },
    computed: {
      computedItemsToShow() {
        const width = window.innerWidth;
        if (width < 900) {
          return 1;
        } else if (width >= 900 && width < 1300) {
          return 2;
        } else {
          return 2.3;
        }
      }
    },
    mounted() {
      window.addEventListener('resize', this.handleResize);
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.handleResize);
    },
    methods: {
      handleResize() {
        this.$forceUpdate();
      }
    }
  });

  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>
    @import "@/styles/components/evmobility/evhero.scss";
  </style>

<style lang="scss">
@import '@/styles/import/variables.scss';
@import '@/styles/import/mixins.scss';

#evmobility {
  .hero {
    &__content {
      .formcta {
        background-color: transparent;
        &__content {
          position: relative;
          top: -90px;
          padding: 0 60px;
          display: flex;
          width: fit-content;
          margin-right: 0;
          @include upto ($breakpoint_lg) {
            top: -70px;
            right: -30%;
          }
          @include upto ($breakpoint_md) {
            top: -85px;
            padding: 0 50px;
            right: -15%;
          }
          @include upto ($breakpoint_sm) {
            top: 0;
            padding: 0;
            right: 0;
            width: auto;
            margin: 30px auto;
          }
        }
        &__btn {
          padding: 7px 40px;
          color: $sequire-dark;
          @include upto ($breakpoint_md) {
            padding: 7px 20px;
          }
          @include upto ($breakpoint_sm) {
            width: auto;
          }
          &:hover {
            color: $yellow;
          }
        }
      }
    }
  }

  .carousel__prev,
  .carousel__next {
    background-color: #fff;
    border-radius: 50%;
    &:hover {
      color: #000;
    }
  }
  .carousel__pagination-button::after {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: rgb(255 255 255 / 15%);
  }

  .carousel__pagination-button--active::after,
  .carousel__pagination-button--active:hover::after {
    background-color: #fff !important;
  }

  .carousel__pagination-button:hover::after{
    background-color: rgb(255 255 255 / 50%);
  }

  .carousel__slide > .carousel__item > img {
    opacity: .2;
  }

  .carousel__slide--active.carousel__slide--visible > .carousel__item {
    border-radius: 30px;
    img {
      opacity: 1;
      border-radius: 30px;
    }
  }
  .carousel__pagination {
    margin: 20px 0 0;
    padding: 0;
  }
}
</style>