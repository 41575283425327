import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Evmobility from '../views/Evmobility.vue'
import Venue from '../views/Venue.vue'
import Entertainment from '../views/Entertainment.vue'
import Companies from '../views/Presenting.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/fly-with-evmobility',
    name: 'Evmobility',
    component: Evmobility
  },
  {
    path: '/venue',
    name: 'Venue',
    component: Venue
  },
  {
    path: '/entertainment',
    name: 'Entertainment',
    component: Entertainment
  },
  {
    path: '/companies',
    name: 'Companies',
    component: Companies
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
