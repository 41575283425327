<template>
  <section class="dining">
    <div class="dining__fine">
      <div class="dining__header container">
        <h1 class="dining__title">
          Fine Dining
        </h1>
        <p class="dining__description">
          If you are what you eat, then prepare to feel indulgent because the fine dining options at Condado Vanderbilt are designed to pamper your palate.
        </p>
      </div>
      <div class="dining__content container">
        <div class="dining__content--column">
          <img src="@/assets/venue/stk-rest.png" class="restaurant" alt="STK Restaurant">
          <img src="@/assets/venue/stk.png" class="dining__logo" alt="STK Restaurant Logo">
          <h1 class="dining__title">
            STK Restaurant
          </h1>
          <p class="dining__description">
            As one might surmise, STK specializes in prime, tender cuts of perfectly-prepared protein –
            but there's more to this beef-inspired bistro than the name implies.
          </p>
          <a href="https://www.condadovanderbilt.com/dine/stk-restaurant"
              target="_blank" class="dining__btn btn">
            <button class="button">Learn More</button>
          </a>
        </div>
        <div class="dining__content--column">
          <img src="@/assets/venue/chef.png" class="restaurant" alt="1919 Restaurant">
          <img src="@/assets/venue/1919.png" class="dining__logo" alt="1919 Restaurant Logo">
          <h1 class="dining__title">
            1919 Restaurant
          </h1>
          <p class="dining__description">
            Exquisite food with a view to match – that, at its core, is what 1919 is all about. Named for
            the year Condado Vanderbilt first opened its doors, this unparalleled tour-de-force in
            fine dining offers contemporary, "flavor-forward" dishes crafted exclusively from local artisans and farmers.
          </p>
          <a href="https://www.condadovanderbilt.com/dine/1919-restaurant"
              target="_blank" class="dining__btn btn">
            <button class="button">Learn More</button>
          </a>
        </div>
      </div>
    </div>
    <div class="dining__ocean">
      <div class="dining__header container">
        <h1 class="dining__title">
          Oceanfront Dining
        </h1>
        <p class="dining__description">
          Do refreshing breezes and the sight of shimmering turquoise waters whet your appetite?
          Then you're in for a treat because the Vanderbilt has not one but two oceanfront dining experiences.
        </p>
      </div>
      <div class="dining__content container">
        <div class="dining__content--column">
          <img src="@/assets/venue/ola.png" class="restaurant" alt="Ola Oceanfront Restaurant">
          <img src="@/assets/venue/ola-logo.png" class="dining__logo" alt="Ola Oceanfront Restaurant Logo">
          <h1 class="dining__title">
            Ola Oceanfront
          </h1>
          <p class="dining__description">
            "Ola" – the Spanish word for "wave" – welcomes visitors with a relaxed, family-friendly
            vibe and authentic fare brimming with the traditional flavors of Puerto Rico.
          </p>
          <a href="https://www.condadovanderbilt.com/dine/ola-oceanfront"
              target="_blank" class="dining__btn btn">
            <button class="button">Learn More</button>
          </a>
        </div>
        <div class="dining__content--column">
          <img src="@/assets/venue/tacos.png" class="restaurant" alt="Tacos & Tequila Restaurant">
          <img src="@/assets/venue/tacostequila.jpeg" class="dining__logo" alt="Tacos & Tequila Restaurant Logo">
          <h1 class="dining__title">
            Tacos & Tequila
          </h1>
          <p class="dining__description">
            Tacos and Tequila is a tantalizing culinary tribute to the most beloved dishes of Mexico.
          </p>
          <a href="https://www.condadovanderbilt.com/dine/tacos-and-tequila"
              target="_blank" class="dining__btn btn">
            <button class="button">Learn More</button>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>


<script>

export default {
  name: 'dining',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/styles/components/venue/dining.scss";
</style>