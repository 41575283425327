<template>
  <section class="reserve">
    <div class="reserve__content container">
      <div class="reserve__content--headline">
        <img class="ev-logo" src="@/assets/evmobility/ev-logo-nobg.svg">
        <h1 class="reserve__title">
          Reserve Your Spot
        </h1>
        <p class="reserve__description">
          If you're a qualified investor, seize the opportunity to be one of the privileged
          few aboard this luxe aircraft to the Sequire Investor Summit in Puerto Rico
          by registering for the <b>EV Mobility IPO Webinar.</b>
          Space is limited out of each city and the EV Mobility team will review the registrants
          to determine an optimized audience on each plane. We will see you at the webinar
          and be in touch on who will be on the planes from each city.
          Travel on the plane is subject to the completion of the EVmobility IPO before the date of the event.
        </p>
        <p class="reserve__description--date">
          Register and watch the webinar's replay here
        </p>
        <a href="https://audience.mysequire.com/webinar-view?webinar_id=cb100a83-496f-43fa-8228-a53d988d02b3" target="_blank" class="reserve__btn btn">
          <button class="button">Watch Replay</button>
        </a>
      </div>
      <div class="reserve__content--form">
        <evform></evform>
      </div>
    </div>
  </section>
</template>


<script>
import evform from "@/components/evmobility/evform.vue";

export default {
  name: 'EV Reserve',
  components: {
    evform
  },
}
</script>

<style lang="scss" scoped>
  @import "@/styles/components/evmobility/evreserve.scss";
</style>

<style lang="scss">
@import '@/styles/import/variables.scss';
@import '@/styles/import/mixins.scss';

.reserve {
  padding: 0 20px;
  form {
    h1 {
      margin: 0 0 10px;
      font-size: 22px;
      color: #fff;
      font-weight: 500;
      @include upto ($breakpoint_lg) {
        text-align: center;
      }
    }
  }
  .form {
    &__fields {
      display: flex;
      align-items: flex-end;
      gap: 15px;
      @include upto ($breakpoint_lg) {
        align-items: center;
        gap: 20px;
        flex-direction: column;
      }
      &--box {
        @include upto ($breakpoint_lg) {
          width: 80%;
        }
        @include upto ($breakpoint_sm) {
          width: 100%;
        }
        p {
          margin: 0 0 5px;
          color: #ffffff;
          display: block;
          top: 0;
          right: 0;
          padding: 0;
          background-color: transparent;
        }
        input[type=text], input[type=search], input[type=file], input[type=password], input[type=url], input[type=tel], input[type=email], input[type=number], input[type=date], select, textarea {
          margin-bottom: 0 !important;
          padding: 5px 10px;
          border-color: #8493AB;
          background-color: transparent;
          color: #fff;
          &:focus {
            border-color: #12A19A;
          }
        }
        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover,
        select:-webkit-autofill,
        select:-webkit-autofill:hover{
          background-color: transparent;
          -webkit-text-fill-color: #fff;
          transition: background-color 5000s ease-in-out 0s;
        }
      }
    }
    &__submit {
      input {
        padding: 5px 50px;
        color: #05090F;
      }
    }
  }
  #subscribe-result {
    color: rgb(255 255 255);
    @media only screen and (min-width: 1px) and (max-width: 320px) {
      position: relative;
      top: 0;
    }
    .text {
      h1 {
        color: #ffffff;
        font-weight: 500;
      }
      p {
        color: #ffffff;
      }
    }
  }
}
</style>