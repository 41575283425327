<template>
  <div>
    <form
        id="subscribe-form"
        action="https://list-manage.us1.list-manage.com/subscribe/post-json?u=b0dec55260536a21ace4f46e7&amp;id=8b64948565&amp;f_id=005e34e1f0"
        method="get">
        <h1>Registration form</h1>
        <div class="form__fields ev-mobility">
          <div class="form__fields--box">
            <p>Name <b>*</b></p>
            <input type="text" value="" name="FNAME">
          </div>
          <div class="form__fields--box">
            <p>Email Address<b>*</b></p>
            <input type="email" value="" name="EMAIL">
          </div>
          <div class="form__fields--box">
            <p>Phone Number</p>
            <input type="text" value="" name="PHONE">
          </div>
          <div class="form__fields--box">
            <p>Job Title<b>*</b></p>
            <input type="text" value="" name="MMERGE3">
          </div>
          <div class="form__fields--box">
            <p>Company Name<b>*</b></p>
            <input type="text" value="" name="LNAME">
          </div>

          <div hidden="true"><input type="hidden" name="tags" value="3434909"></div>
          <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
          <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_b0dec55260536a21ace4f46e7_8b64948565" tabindex="-1" value=""></div>

          <div class="form__submit">
            <input type="submit" value="Submit" name="subscribe">
          </div>
        </div>
    </form>
    <div id="subscribe-result">
    </div>
  </div>

</template>

<style lang="scss" scoped>
  @import '@/styles/components/contactform.scss';
</style>

<script>
import $ from 'jquery'

export default {
  name: 'Popup',
  mounted() {
    $(document).ready(function(){
    ajaxMailChimpForm($("#subscribe-form"), $("#subscribe-result"));

    // Turn the given MailChimp form into an ajax version of it.
    // If resultElement is given, the subscribe result is set as html to
    // that element.
    function ajaxMailChimpForm($form, $resultElement){

        // Hijack the submission. We'll submit the form manually.
        $form.submit(function(e) {
            e.preventDefault();

            if (!isValidEmail($form)) {
                var error =  "A valid email address must be provided.";
                $resultElement.html("<div class='text'>" + error + "</div>");
                $resultElement.css("color", "red");
            } else {
                $resultElement.css("color", "#232323");
                $resultElement.html("<div class='text'>Sending...</div>");
                submitSubscribeForm($form, $resultElement);
            }
        });
    }

    // Validate the email address in the form
    function isValidEmail($form) {
        // If email is empty, show error message.
        // contains just one @
        var email = $form.find("input[type='email']").val();
        if (!email || !email.length) {
            return false;
        } else if (email.indexOf("@") == -1) {
            return false;
        }

        return true;
    }

    // Submit the form with an ajax/jsonp request.
    // Based on http://stackoverflow.com/a/15120409/215821
    function submitSubscribeForm($form, $resultElement) {
        $.ajax({
            type: "GET",
            url: $form.attr("action"),
            data: $form.serialize(),
            cache: false,
            dataType: "jsonp",
            jsonp: "c", // trigger MailChimp to return a JSONP response
            contentType: "application/json; charset=utf-8",

            /* eslint-disable */ 
            error: function(error){
                // According to jquery docs, this is never called for cross-domain JSONP requests
            },

            success: function(data){
                if (data.result != "success") {
                    var message = data.msg || "<div class='text'> Sorry. Unable to subscribe. Please try again later.</div>";
                    $resultElement.css("color", "red");

                    if (data.msg && data.msg.indexOf("already subscribed") >= 0) {
                        message = "<div class='text'>You're already subscribed. Thank you.</div>";
                        $resultElement.css("color", "#232323");
                    }

                    $resultElement.html(message);

                } else {
                    $resultElement.css("color", "#232323");
                    $resultElement.html("<div class='text'><h1>Thank you!</h1> <p>Your information has been submitted. <br>Please check your email inbox for a confirmation message in order to complete your registration.</p></div>");
                    $form.css({
                        display: 'none'
                    });
                }
            }
        });
    }
  });
  },
};
</script>
