<template>
  <Menu buttonText="2024 Event Registration" />
  <main id="evmobility">
    <evhero />
    <evreserve />
    <evperks />
    <evattend />
    <evquote />
  </main>
</template>

<style lang="scss" scoped>
  @import '../styles/pages/home.scss';
</style>

<script>
import Menu from '@/components/menu';
import evhero from '@/components/evmobility/evhero.vue';
import evreserve from '@/components/evmobility/evreserve.vue';
import evperks from '@/components/evmobility/evperks.vue';
import evattend from '@/components/evmobility/evattend.vue';
import evquote from '@/components/evmobility/evquote.vue';

export default {
  name: 'Evmobility',
  data() {
    return {
    };
  },
  components: {
    Menu,
    evhero,
    evreserve,
    evperks,
    evattend,
    evquote
},
};
</script>
