<template>
  <section class="services">
    <div class="services__butler">
      <div class="services__content container">
        <div class="services__content--image">
          <img src="@/assets/venue/butler.png" alt="Pool & Drinks">
        </div>
        <div class="services__content--info">
          <h1 class="services__title">
            Personalized Butler Service
          </h1>
          <p class="services__description">
            While every hotel guest enjoys twice-daily housekeeping and a full range of concierge services,
            visitors who elect to stay in a Condado Vanderbilt Commodore Oceanfront Suite can further
            personalize their experiences with the complimentary assistance of a professional butler.
            Your butler will unpack and organize your belongings, prepare a customized activities agenda,
            and fulfill personal requests.
          </p>
          <a href="https://www.condadovanderbilt.com/"
              target="_blank" class="services__btn btn">
            <button class="button">Learn More</button>
          </a>
        </div>
      </div>
    </div>
    <div class="services__spa">
      <div class="services__content container">
        <div class="services__content--image">
          <img src="@/assets/venue/spa.png" alt="Spa">
        </div>
        <div class="services__content--info">
          <h1 class="services__title">
            Rejuvenation at The Spa
          </h1>
          <p class="services__description">
            Our Spa offers a variety of indulgent experiences with far-from-typical treatments
            designed to soothe and renew. It's a tranquil retreat dedicated to rejuvenation,
            relaxation, and mind-body harmony. Specializing in treatments that promote personal
            health and wellness, a session at The Spa, along with highly effective therapeutic
            products, will result in a palpable sense of renewal.
          </p>
          <a href="https://www.condadovanderbilt.com/spa-and-fitness/"
              target="_blank" class="services__btn btn">
            <button class="button">Learn More</button>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>


<script>

export default {
  name: 'Services',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/styles/components/venue/services.scss";
</style>