<template>
  <Menu buttonText="Register for 2025" />
  <main id="home">
    <presenters />
  </main>
</template>

<style lang="scss" scoped>
  @import '../styles/pages/home.scss';
</style>

<script>
// @ is an alias to /src
import Menu from '@/components/menu';
import presenters from '@/components/presenters.vue';

export default {
  name: 'Home',
  data() {
    return {
    };
  },
  components: {
    Menu,
    presenters,
  },
};
</script>
