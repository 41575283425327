<template>
  <section class="ehero">
      <div class="ehero__content container">
        <div class="ehero__content--info">
          <h1 class="ehero__title">
            After a day filled with enlightening presentations and captivating keynotes, it's time to unwind and connect!
            Join us for specially curated networking opportunities designed just for you.
          </h1>
          <p class="ehero__description">
            Don't miss this chance to relax, mingle, and foster meaningful connections with fellow attendees.
            Your next big opportunity could be just a conversation away. Let the networking begin!
          </p>
        </div>
      </div>
    </section>
  </template>
  
  <script>

  export default {
    name: 'Entertainment Hero',
    data () {
      return {
      }
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>
  @import "../../styles/components/entertainment/ehero.scss";
  </style>