<template>
  <section class="culinary">
    <div class="culinary__content">
      <div class="culinary__card">
        <h1 class="culinary__title">
          Culinary Delights Await
        </h1>
        <p class="culinary__description">
          When it comes to dining and entertainment, the Condado Vanderbilt Hotel sets the stage
          for a remarkable culinary journey. With a wide variety of dining options, ranging from
          chic lounges and classic bars to casual bites, outdoor and formal dining, there's certainly 
          no shortage of ways to please your palate.
        </p>
        <a href="https://www.condadovanderbilt.com/dine/"
            target="_blank" class="culinary__btn btn">
          <button class="button">Learn More</button>
        </a>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  @import '@/styles/components/venue/culinary.scss';
</style>

<script>

export default {
  name: 'Breathtaking culinary',
};
</script>