<template>
  <section class="stay">
    <div class="stay__content container">
      <div class="stay__content--image">
        <img src="@/assets/hotel/hotel-stay.png" alt="Hotel Room">
      </div>
      <div class="stay__content--info">
        <h1 class="stay__title">
          Luxurious Stay
        </h1>
        <p class="stay__description">
          Experience pure opulence with our sumptuous accommodations. Inside your haven,
          you'll find thoughtful interior touches that include 300 thread-count Rivoita Carmignani
          linens, plush bedding, and a spa-inspired bath featuring marble showers and stone-topped
          vanities. This is where you'll discover the true meaning of luxury.
        </p>
        <a href="/#book" class="stay__btn btn">
          <button class="button book">Book Now</button>
        </a>
      </div>
    </div>
  </section>
</template>


<script>

export default {
  name: 'Stay',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/styles/components/venue/stay.scss";
</style>