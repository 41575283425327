<template>
  <section class="speakers">
    <div class="speakers__content">
      <h1 class="speakers__title">
          2024 Featured Speakers
      </h1>
      <div class="speakers__cards">
        <div class="speakers__cards--single">
          <button @click="pierce = true" class="speaker-btn">
            <img src="../assets/speakers/Brock.png" alt="Brock Pierce">
            <h2>Brock Pierce</h2>
            <h4>Chairman of the Bitcoin Foundation, Presidential Candidate</h4>
          </button> 
          <transition name="fade" appear>
            <div class="modal-overlay" v-if="pierce" @click="pierce = false"></div>
          </transition>
          <transition name="pop" appear>
            <div class="modal" role="dialog" v-if="pierce">
              <div class="speaker-modal">
                <div class="speaker-thumbnail">
                  <img src="../assets/speakers/Brock.png" alt="Brock Pierce">
                </div>
                <div class="speaker-info">
                  <h2>Brock Pierce</h2>
                  <h3>Chairman of the Bitcoin Foundation, Presidential Candidate</h3>
                  <p>Brock Pierce is a futurist, entrepreneur, philanthropist, and
                  impact investor with an extensive track record of founding,
                  advising and investing in disruptive businesses. He s
                  credited with pioneering the market for digital currency and
                  has raised more than $5B for companies he has founded,
                  opening opportunities for millions of people. Pierce is the
                  Chairman of the Bitcoin Foundation and co-founder of EOS
                  Alliance, Block.one, Blockchain Capital, Tether, and
                  Mastercoin (first ICO).</p>
                </div>
              </div>
              <button @click="pierce = false" class="close">⨉</button>
            </div>
          </transition>
        </div>
        <div class="speakers__cards--single">
          <button @click="jon = true" class="speaker-btn">
            <img src="../assets/speakers/Najarian.jpg" alt="Jon “DRJ” Najarian">
            <h2>Jon “DRJ” Najarian</h2>
            <h4>Market Rebellion - Co-Founder</h4>
          </button>
          <transition name="fade" appear>
            <div class="modal-overlay" v-if="jon" @click="jon = false"></div>
          </transition>
          <transition name="pop" appear>
            <div class="modal" role="dialog" v-if="jon">
              <div class="speaker-modal">
                <div class="speaker-thumbnail">
                  <img src="../assets/speakers/Najarian.jpg" alt="Jon “DRJ” Najarian">
                </div>
                <div class="speaker-info">
                  <h2>Jon “DRJ” Najarian</h2>
                  <h3>Market Rebellion - Co-Founder</h3>
                  <p>Jon 'DRJ' Najarian was linebacker for the Chicago Bears before he turned to another
                    kind of a contact sport - trading on the Chicago Board Options Exchange. He became
                    a member of the CBOE, NYSE, CME and CBOT and worked as a floor trader for some
                    25 years. In 1990 he founded Mercury Trading, a market-making firm at the Chicago
                    Board Options Exchange (CBOE), which he sold in 2004 to Citadel, one of the world's
                    largest hedge funds. In 2005 Jon co-founded optionMONSTER and tradeMONSTER,
                    sold a majority stake to private equity firm General Atlantic Partners in 2014 & two years
                    later sold the firm to E*Trade for $750M. Today, he is a host on a variety of mainstream
                    financial news netwoks, a professional investor, money manager, market strategist and
                    venture evangelist. Jon is also a co-host and co-founder of proprietary programming
                    including the popular “Rebels' Edge” streaming show and the “Bulls, Bears &
                    Blockchain” radio show.
                    <br><br>
                    Jon is also chairman of a technology SPAC, an investor and senior advisor to IDI
                    Group, LLC (a multi-family office) and co-founder of Moneta Advisory Partners, LLC, a
                    “venture evangelism” platform that raises awareness of a portfolio of growing public
                    companies.
                  </p>
                </div>
              </div>
              <button @click="jon = false" class="close">⨉</button>
            </div>
          </transition>
        </div>
        <div class="speakers__cards--single">
          <button @click="terpin = true" class="speaker-btn">
            <img src="../assets/speakers/Terpin.jpg" alt="Michael Terpin">
            <h2>Michael Terpin</h2>
            <h4>Transform Group, BitAngels - Founder and CEO</h4>
          </button>
          <transition name="fade" appear>
            <div class="modal-overlay" v-if="terpin" @click="terpin = false"></div>
          </transition>
          <transition name="pop" appear>
            <div class="modal" role="dialog" v-if="terpin">
              <div class="speaker-modal">
                <div class="speaker-thumbnail">
                  <img src="../assets/speakers/Terpin.jpg" alt="Michael Terpin">
                </div>
                <div class="speaker-info">
                  <h2>Michael Terpin</h2>
                  <h3>Transform Group, BitAngels - Founder and CEO</h3>
                  <p>
                    <a href="https://cointelegraph.com/news/us-blockchain-investor-terpin-awarded-over-75-million-in-sim-swapping-case" target="_blank">Michael Terpin</a>
                    is an accomplished entrepreneur, investor, and public relations
                    expert who has made significant contributions to the blockchain and cryptocurrency
                    industry. He has been an influential figure in the space since its early days
                    and has played a vital role in the development and growth of the industry.
                    <br><br>
                    Terpin began his career as the
                    <a href="https://cointelegraph.com/news/us-blockchain-investor-terpin-awarded-over-75-million-in-sim-swapping-case" target="_blank">CEO of Terpin Communications</a>
                    in 1990, which he ran until 2001 when he sold it to Morgen-Walke/Lighthouse, now Financial Dynamics.
                    In 1994, he founded Internet Wire, which became the first Internet-based company
                    newswire and was later rebranded as Marketwired. During his time as CEO of Marketwired,
                    Terpin introduced a range of innovative solutions to the PR industry, including the
                    first multi-featured online newsroom, earning him a reputation as a pioneer in the field of internet-based PR.
                    <br><br>
                    Terpin has also been an active investor in the blockchain and cryptocurrency space and has
                    made early investments in some of the industry's most successful startups, including Ethereum,
                    Factom, and Augur. He is a <a href="https://www.coindesk.com/author/michael-terpin/" target="_blank">frequent speaker at industry conferences</a>
                    and is widely regarded as an expert on blockchain, cryptocurrency, and PR.
                  </p>
                </div>
              </div>
              <button @click="terpin = false" class="close">⨉</button>
            </div>
          </transition>
        </div>
        <div class="speakers__cards--single">
          <button @click="ella = true" class="speaker-btn">
            <img src="../assets/speakers/Ella.jpg" alt="Ella Woger Nieves">
            <h2>Ella Woger Nieves</h2>
            <h4>Invest Puerto Rico - Chief Executive Officer</h4>
          </button>
          <transition name="fade" appear>
            <div class="modal-overlay" v-if="ella" @click="ella = false"></div>
          </transition>
          <transition name="pop" appear>
            <div class="modal" role="dialog" v-if="ella">
              <div class="speaker-modal">
                <div class="speaker-thumbnail">
                  <img src="../assets/speakers/Ella.jpg" alt="Ella Woger Nieves">
                </div>
                <div class="speaker-info">
                  <h2>Ella Woger Nieves</h2>
                  <h3>Invest Puerto Rico - Chief Executive Officer</h3>
                  <p>
                    A Puerto Rico native, Ella brings 20 years of experience in strategic marketing and
                    management consulting to strengthen Invest Puerto Rico's endeavors. She played an
                    instrumental role in the organization's creation, helping establish InvestPR's mission,
                    objectives, and roadmap to accomplishing them. A true believer in the island's potential as a
                    world-class investment destination, Ella ensures that all stakeholders - internal and external
                    - are aligned to InvestPR's vision of being a transformational and results-oriented accelerator
                    of economic development in Puerto Rico.
                  </p>
                </div>
              </div>
              <button @click="ella = false" class="close">⨉</button>
            </div>
          </transition>
        </div>
        <div class="speakers__cards--single lg-bio">
          <button @click="marc = true" class="speaker-btn">
            <img src="../assets/speakers/LoPresti.png" alt="Marc X. LoPresti">
            <h2>Marc X. LoPresti</h2>
            <h4>IDI Group - Founder & Managing Director</h4>
          </button>
          <transition name="fade" appear>
            <div class="modal-overlay" v-if="marc" @click="marc = false"></div>
          </transition>
          <transition name="pop" appear>
            <div class="modal" role="dialog" v-if="marc">
              <div class="speaker-modal">
                <div class="speaker-thumbnail">
                  <img src="../assets/speakers/LoPresti.png" alt="Marc X. LoPresti">
                </div>
                <div class="speaker-info">
                  <h2>Marc X. LoPresti</h2>
                  <h3>IDI Group - Founder & Managing Director</h3>
                  <p>
                    Marc is a Wall Street veteran with over 25+ years' experience in alternative
                    investments, having started his career on the floor of the NYSE working for Lehman
                    Brothers. Marc is currently: Co-Founder & Managing Director, IDI Group (a global multi-
                    family office focused on early-stage venture investments), Co-Founder & Managing
                    Director, Moneta Advisory Partners (a “Venture Evangelism” platform), Co-Founder &
                    Board Member, BattleFin Group (a leading alternative data company), Co-Founder,
                    Revolution Media Group (producer of the popular “Bulls, Bears & Blockchain” podcast),
                    Board Member, Market Rebellion, LLC. Marc was also the founder of LoPresti Law
                    Group, LLC, a leading securities law firm in New York focused on alternative
                    investments.
                    <br><br>
                    A recognized market strategist and authority on alternative investments, Marc is a co-
                    founder of Moneta Advisory Partners, LLC, a “venture evangelism” platform that raises
                    awareness of a portfolio of growing public companies.
                    <br><br>
                    Marc appears regularly on Fox Business, CNBC, CoindeskTV, Schwab Network, Sirius
                    XM Radio, Bloomberg, and Fox. He has been quoted in numerous publications
                    including Forbes, Reuters, the Wall Street Journal, Investor's Business Daily, and
                    Barron's, and is a co-host and co-founder and co-host of proprietary programming
                    including the popular “Rebels' Edge” streaming show and the “Bulls, Bears &
                    Blockchain” radio show. He is also a frequently featured speaker on investment panels
                    at conferences around the world. Marc graduated from New York Law School in 1997.
                  </p>
                </div>
              </div>
              <button @click="marc = false" class="close">⨉</button>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  @import '@/styles/components/speakers.scss';
</style>

<script>

export default {
  name: 'Speakers',

  data() {
    return {
      pierce: false,
      jon: false,
      terpin: false,
      putra: false,
      marc: false,
      pham: false,
      schiff: false,
      hayes: false,
      karabell: false,
      divine: false,
      ella: false,
      bryn: false,
      gonta: false
    };
  },
};
</script>