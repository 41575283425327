<template>
  <footer class="main-footer">
    <hr>
    <section class="logo container">
      <div class="logo__container">
        <img src="../assets/logo.png" alt="Sequire Logo">
      </div>
      <div class="logo__text">
        <p>If you have any questions, send us an email at <a href="mailto:puertorico@srax.com">puertorico@srax.com</a></p>
      </div>
    </section>
  </footer>
</template>

<style lang="scss" scoped>
  @import '@/styles/components/footer.scss';
</style>

<script>
export default {
  name: 'Footer',
  data() {
    return {
    };
  },
};
</script>