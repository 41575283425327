<template>
  <section class="vcta">
    <div class="vcta__content container">
      <h2 class="vcta__title">
        Book your stay now and discover the magic of
        Condado Vanderbilt Hotel in San Juan, Puerto Rico.
      </h2>
      <a href="https://www.condadovanderbilt.com/"
          target="_blank" class="stay__btn btn">
        <button class="button book">Book Now</button>
      </a>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Book Vanderbilt CTA',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "@/styles/components/venue/vcta.scss";
</style>