<template>
    <section class="location">
      <div class="location__banner">
        <div class="location__banner--content container">
          <h1 class="location__title">
            Condado Vanderbilt Hotel in San Juan, Puerto Rico
          </h1>
          <h2 class="location__subtitle">
            January 21st - 23rd, 2025
          </h2>
        </div>
      </div>
    </section>
  </template>
  
  <style lang="scss" scoped>
    @import '@/styles/components/location.scss';
  </style>
  
  <script>

  export default {
    name: 'location',
    components: {
      
    },
    data() {
      return {
      };
    },
  };
  </script>