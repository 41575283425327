<template>
  <section class="experience">
    <div class="experience__content">
      <div class="experience__content--info">
        <h1 class="experience__title">
          Your Condado Vanderbilt Experience Awaits
        </h1>
        <p class="experience__description">
          From luxurious stays to exceptional dining and entertainment, the Condado Vanderbilt Hotel is
          your gateway to a world of indulgence. Join us in paradise and make memories that will last
          a lifetime. Your unparalleled experience begins here.
        </p>
        <a href="/#book" class="stay__btn btn">
          <button class="button book">Book Now</button>
        </a>
      </div>
      <div class="experience__content--image">
        <img src="@/assets/venue/hotelview.png" alt="Condado Vanderbilt Hotel View">
      </div>
    </div>
  </section>
</template>


<script>

export default {
  name: 'Condado Vanderbilt Experience',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/styles/components/venue/experience.scss";
</style>