<template>
  <section class="hero">
      <div class="hero__content container">
        <div class="hero__content--info">
          <h1 class="hero__title">
            Sequire Investor Summit
          </h1>
          <h2 class="hero__date">
            January 21st - 23rd, 2025
          </h2>
          <h3 class="hero__location">
            Condado Vanderbilt, Puerto Rico
          </h3>
          <a href="https://www.condadovanderbilt.com/specials?ds_rl=1250973&gclid=EAIaIQobChMIydqAzKrsgQMVrCqzAB1C0gl6EAAYASAAEgLIhfD_BwE&gclsrc=aw.ds#/booking/step-1?data=('h8hd!'condado-new'~a9dt3fs.~r8at!2~cn!0~cg.~al6po3gp!'012125SRAX20'~rn.)7ry3rk3re.~rr*)7ax!0~cy3ds!('p9ls3as3st*)~my6s9c9ne*)*!null.4%5D3*~4!%5B6!false~7%5D~8s4('9e3%01987643.*_" target="_blank">
            <button class="hero__btn">
              Book Now
            </button>
          </a>
        </div>
        <iframe 
          class="hero__content--video"
          width="520" 
          height="247" 
          src="https://www.youtube.com/embed/y193tBLlw-g?si=CL8KVp9zWBBKXnm0&amp;controls=0" 
          title="YouTube video player" 
          frameborder="0" 
          allow="accelerometer; 
          autoplay; 
          clipboard-write; 
          encrypted-media; 
          gyroscope; 
          picture-in-picture" 
          allowfullscreen>
        </iframe>
      </div>
    </section>
  </template>
  
  <script>

  export default {
    name: 'Hero',
    data () {
      return {
      }
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>
  @import "../styles/components/hero.scss";
  </style>