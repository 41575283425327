<template>
  <section class="welcome">
      <div class="welcome__content container">
        <div class="welcome__content--headline">
          <h1 class="welcome__title">
            Exclusive Room Rates at the Luxurious Condado Vanderbilt for Conference Attendees
            - Book Your Stay Now!
          </h1>
        </div>
      </div>
      <div class="welcome__content--slider">
        <Carousel :items-to-show="computedItemsToShow" :wrap-around="true" v-model="currentSlide">
          <Slide v-for="(image, index) in images" :key="index">
            <div class="carousel__item">
              <img :src="image" :alt="'Image ' + index">
            </div>
          </Slide>
          <template #addons>
            <Pagination />
            <Navigation />
          </template>
        </Carousel>
      </div>
      <div class="welcome__content--info container">
        <h3 class="welcome__subtitle">
          Unparalleled Luxury Awaits
        </h3>
        <p class="welcome__description">
          At the Condado Vanderbilt Hotel, we redefine the art of relaxation and pampering,
          offering you a stay like no other. With 317 gracious guest rooms, including 100 exquisite
          suites, our hotel invites honored guests to bask in the luxury and comfort of Puerto Rico's
          finest resort. Let us take you on a journey through the amazing options for staying in.
        </p>
        <a href="/#book" class="welcome__btn btn">
          <button class="button">Book Now</button>
        </a>
      </div>
    </section>
  </template>
  
  <script>
  import { defineComponent } from 'vue';
  import { Carousel, Navigation, Slide, Pagination } from 'vue3-carousel';
  import 'vue3-carousel/dist/carousel.css';

  export default defineComponent({
    name: 'Venue Welcome',
    data() {
      return {
        images: [
          require('@/assets/hotel/hotel-6.jpeg'),
          require('@/assets/hotel/hotel-7a.jpeg'),
          require('@/assets/hotel/hotel-5.jpeg'),
          require('@/assets/hotel/hotel-11a.jpeg'),
          require('@/assets/hotel/hotel-14.jpeg'),
          require('@/assets/hotel/hotel-25.jpeg'),
          require('@/assets/hotel/hotel-21.jpeg')
        ],
        currentSlide: 0,
      };
    },
    components: {
      Carousel,
      Slide,
      Navigation,
      Pagination
    },
    computed: {
      computedItemsToShow() {
        const width = window.innerWidth;
        if (width < 900) {
          return 1;
        } else if (width >= 900 && width < 1300) {
          return 2;
        } else {
          return 2.3;
        }
      }
    },
    mounted() {
      window.addEventListener('resize', this.handleResize);
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.handleResize);
    },
    methods: {
      handleResize() {
        this.$forceUpdate();
      }
    }
  });

  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>
    @import "@/styles/components/venue/welcome.scss";
  </style>

<style lang="scss">
@import '@/styles/import/variables.scss';
@import '@/styles/import/mixins.scss';

#venue {
  .welcome {
    &__content {
      .formcta {
        background-color: transparent;
        &__content {
          position: relative;
          top: -90px;
          padding: 0 60px;
          display: flex;
          width: fit-content;
          margin-right: 0;
          @include upto ($breakpoint_lg) {
            top: -70px;
            right: -30%;
          }
          @include upto ($breakpoint_md) {
            top: -85px;
            padding: 0 50px;
            right: -15%;
          }
          @include upto ($breakpoint_sm) {
            top: 0;
            padding: 0;
            right: 0;
            width: auto;
            margin: 30px auto;
          }
        }
        &__btn {
          padding: 7px 40px;
          color: $sequire-dark;
          @include upto ($breakpoint_md) {
            padding: 7px 20px;
          }
          @include upto ($breakpoint_sm) {
            width: auto;
          }
          &:hover {
            color: $yellow;
          }
        }
      }
    }
  }

  .carousel__prev,
  .carousel__next {
    background-color: #fff;
    border-radius: 50%;
    &:hover {
      color: #000;
    }
  }
  .carousel__pagination-button::after {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: rgb(255 255 255 / 15%);
  }

  .carousel__pagination-button--active::after,
  .carousel__pagination-button--active:hover::after {
    background-color: #fff !important;
  }

  .carousel__pagination-button:hover::after{
    background-color: rgb(255 255 255 / 50%);
  }

  .carousel__slide > .carousel__item > img {
    opacity: .2;
    border-radius: 30px;
  }

  .carousel__slide--active.carousel__slide--visible > .carousel__item {
    border-radius: 30px;
    img {
      opacity: 1;
      border-radius: 30px;
    }
  }
  .carousel__pagination {
    margin: 20px 0 0;
    padding: 0;
  }
}
</style>