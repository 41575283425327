<template>
  <section class="views">
    <div class="views__content container">
      <div class="views__card">
        <h1 class="views__title">
          Breathtaking Ocean Views
        </h1>
        <p class="views__description">
          Here, tasteful and timeless design subtly blends the distinction between indoors and out.
          Sweeping views of the Caribbean are framed by elegant French doors, which open to private
          terraces and balconies. Welcome to paradise!
        </p>
        <a href="/#book" class="views__btn btn">
          <button class="button book">Book Now</button>
        </a>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  @import '@/styles/components/venue/views.scss';
</style>

<script>

export default {
  name: 'Breathtaking Views',
};
</script>