<template>
  <section class="quote">
    <div class="quote__content container">
      <h2 class="quote__title">
        This is no ordinary investor summit;
        it's where <b>intellectual curiosity meets financial ambition,</b>
        where new connections are made, and where limitless possibilities await.
      </h2>
      <img src="@/assets/evmobility/icons/summit-icon.png" alt="Sequire Summit Icon">
      <a href="/" class="quote__btn btn">
        <button class="button">Back to Home</button>
      </a>
    </div>
  </section>
</template>

<script>
export default {
  name: 'EV quote',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "@/styles/components/evmobility/evquote.scss";
</style>