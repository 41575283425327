<template>
  <section class="companypass">
    <div class="companypass__content container">
      <div class="companypass__content--image">
        <img src="@/assets/location/luchetti.jpeg" alt="Luchetti Salon">
      </div>
      <div class="companypass__content--info">
        <h1 class="companypass__title">
          Register early to secure your preferred time and date. Don't miss this exclusive opportunity to showcase
          your innovations at our upcoming event. Act fast—early bird slots are limited with a discount!
        </h1>
        <a href="https://buy.stripe.com/7sI6qSfL02xq3ZudQW" target="_blank" class="companypass__btn btn">
          <button class="button">Get Your Company Pass</button>
        </a>
      </div>
    </div>
  </section>
</template>


<script>

export default {
  name: 'Company Pass',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/styles/components/companypass.scss";
</style>