<template>
  <section class="carousel">
    <div class="carousel__content">
      <Carousel :items-to-show="computedItemsToShow" :wrap-around="true">
        <Slide v-for="(image, index) in images" :key="index">
          <div class="carousel__content--item">
            <img :src="image" :alt="'Image ' + index">
          </div>
        </Slide>
        <template #addons>
          <Navigation class="carousel-nav"/>
        </template>
      </Carousel>
    </div>
  </section>
</template>
  
<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css';

export default defineComponent({
  name: 'Entertainment Carousel',
  components: {
    Carousel,
    Slide,
    Navigation
  },
  computed: {
    computedItemsToShow() {
      const width = window.innerWidth;
      if (width < 500) {
        return 1.5;
      } else if (width >= 500 && width < 900) {
        return 2;
      } else if (width >= 900 && width < 1300) {
        return 2.5;
      } else {
        return 3.2;
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.$forceUpdate();
    }
  },
  data() {
    return {
      images: [
        require('@/assets/entertainment/monastery.jpeg'),
        require('@/assets/entertainment/1919.jpeg'),
        require('@/assets/hotel/hotel-9.jpeg'),
        require('@/assets/entertainment/monastery-2.png'),
        require('@/assets/hotel/hotel-8.jpeg'),
        require('@/assets/hotel/hotel-15.jpeg'),
        require('@/assets/entertainment/avo.jpeg'),
        require('@/assets/entertainment/sanjuan.png')
      ],
    };
  },
});

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../../styles/components/entertainment/carousel.scss";
</style>

<style lang="scss">
@import '@/styles/import/variables.scss';
@import '@/styles/import/mixins.scss';

  .carousel {
    .carousel__prev,
    .carousel__next {
      background-color: #fff;
      border-radius: 50%;
      &:hover {
        color: #000;
      }
    }
    .carousel-nav {
      z-index: 2;
      @include from ($breakpoint_lg) {
        margin: 0 25px;
      }
    }
  }
</style>