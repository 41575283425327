<template>
  <section class="perks">
    <div class="perks__content">
      <div class="perks__content--info">
        <img class="ev-logo" src="@/assets/evmobility/ev-logo-nobg.svg">
        <h1 class="perks__title">
          Extra Perks by EVMobility
        </h1>
        <p class="perks__description">
          Adding a sustainable twist to luxury, our event is proudly sponsored by EV Mobility.
          Known for offering electric vehicles as upscale amenities in hotels and luxury
          apartments, EV Mobility is an electric car-sharing platform with plans to go public
          on the CEOB this year. Watch the replay of EV Mobility IPO webinar.
        </p>
        <a href="https://audience.mysequire.com/webinar-view?webinar_id=cb100a83-496f-43fa-8228-a53d988d02b3" target="_blank" class="perks__btn btn">
          <button class="button">Watch Replay</button>
        </a>
      </div>
      <div class="perks__content--image">
        <img src="@/assets/evmobility/right-img.png" alt="Teslas Charging">
      </div>
    </div>
  </section>
</template>


<script>

export default {
  name: 'EV perks',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/styles/components/evmobility/evperks.scss";
</style>