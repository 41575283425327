<template>
  <section class="presenters">
    <div class="presenters__content container">
      <h1 class="presenters__title">
        2024 Presenting Companies
      </h1>
      <a href="https://schedule.puertorico24.srax.com/"
        target="_blank" class="agenda-btn">
          2024 Agenda
        </a>
      <div class="presenters__cards">
        <div class="presenters__item" v-for="(logo, index) in logos" :key="index" :class="logo.class">
          <div class="presenters__item--logo">
            <img :src="logo.url" :alt="logo.name">
          </div>
          <div class="presenters__item--info">
            <div class="titles">
              <h2>{{ logo.name }}</h2>
              <p>{{ logo.market }}<template v-if="logo.ticker">:</template> {{ logo.ticker }} </p>
            </div>
            <a v-if="logo.path" :href="logo.path" target="_blank">View Company</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <sponsors />
</template>

<style lang="scss" scoped>
  @import '@/styles/components/presenters.scss';
</style>

<script>
import sponsors from '@/components/sponsors.vue';

export default {
  name: 'presenters',
  components: {
    sponsors
  },
  data() {
    return {
      logos: [
        { url: require('../assets/presenters/cibus.png'),
          name: 'Cibus Inc', class: 'cibus',
          market: 'NASDAQ', ticker: 'CBUS',
          path: 'https://www.cibus.com/'
        },
        { url: require('../assets/presenters/idex.png'),
          name: 'Ideanomics Inc', class: 'idex',
          market: 'NASDAQ', ticker: 'IDEX',
          path: 'https://ideanomics.com/'
        },
        { url: require('../assets/presenters/evmobility.png'),
          name: 'EV Mobility, Inc', class: 'evmobility',
          market: 'Private', ticker: '',
          path: 'https://evmobility.com/'
        },
        { url: require('../assets/presenters/wisa.png'),
          name: 'WiSA Technologies Inc', class: 'wisa',
          market: 'NASDAQ', ticker: 'WISA',
          path: 'https://www.wisatechnologies.com/'
        },
        { url: require('../assets/presenters/vox.png'),
          name: 'Vox Royalty Corp', class: 'vox',
          market: 'TSE', ticker: 'VOXR',
          path: 'https://www.voxroyalty.com/'
        },
        { url: require('../assets/presenters/lisata.png'),
          name: 'Lisata Therapeutics Inc', class: 'lisata',
          market: 'NASDAQ', ticker: 'LSTA',
          path: 'https://www.lisata.com/'
        },
        { url: require('../assets/presenters/ryvyl.png'),
          name: 'Ryvyl Inc', class: 'ryvyl',
          market: 'NASDAQ', ticker: 'RVYL',
          path: 'https://www.ryvyl.com/'
        },
        { url: require('../assets/presenters/gohealth.png'),
          name: 'GoHealth Inc', class: 'gohealth',
          market: 'NASDAQ', ticker: 'GOCO',
          path: 'https://www.gohealth.com/'
        },
        { url: require('../assets/presenters/firstwave.png'),
          name: 'First Wave BioPharma, Inc.', class: 'firstwave',
          market: 'NASDAQ', ticker: 'FWBI',
          path: 'https://www.firstwavebio.com/'
        },
        { url: require('../assets/presenters/cyngn.png'),
          name: 'Cyngn Inc', class: 'cyn',
          market: 'NASDAQ', ticker: 'CYN',
          path: 'https://www.cyngn.com/'
        },
        { url: require('../assets/presenters/scienjoy.png'),
          name: 'Scienjoy Holding Corp', class: 'scienjoy',
          market: 'NASDAQ', ticker: 'SJ',
          path: 'https://ir.scienjoy.com/'
        },
        { url: require('../assets/presenters/ipa.png'),
          name: 'Immunoprecise Antibodies Ltd', class: 'ipa',
          market: 'NASDAQ', ticker: 'IPA',
          path: 'https://www.ipatherapeutics.com/'
        },
        { url: require('../assets/presenters/lightwave.png'),
          name: 'Lightwave Logic Inc', class: 'lightwave',
          market: 'NASDAQ', ticker: 'LWLG',
          path: 'https://www.lightwavelogic.com/'
        },
        { url: require('../assets/presenters/solowin.png'),
          name: 'SOLOWIN HOLDINGS', class: 'solomon',
          market: 'NASDAQ', ticker: 'SWIN',
          path: 'https://ir.solomonwin.com.hk/'
        },
        { url: require('../assets/presenters/jackpot.png'),
          name: 'Jackpot Digital Inc', class: 'jackpot',
          market: 'OTCMKTS', ticker: 'JPOTF',
          path: 'https://www.jackpotdigital.com/'
        },
        { url: require('../assets/presenters/oxbr.png'),
          name: 'Oxbridge Re Holdings Ltd', class: 'oxbridge',
          market: 'NASDAQ', ticker: 'OXBR',
          path: 'https://oxbridgere.com/'
        },
        { url: require('../assets/presenters/bactech.png'),
          name: 'Bactech Environmental Corp', class: 'bactech',
          market: 'CNSX', ticker: 'BAC',
          path: 'https://bactechgreen.com/'
        },
        { url: require('../assets/presenters/tcbio.png'),
          name: 'TC BioPharm Ltd', class: 'tcbiopharm',
          market: 'NASDAQ', ticker: 'TCBP',
          path: 'https://tcbiopharm.com/'
        },
        { url: require('../assets/presenters/catheter.png'),
          name: 'Catheter Precision Inc', class: 'catheter',
          market: 'NYSEAMERICAN', ticker: 'VTAK',
          path: 'https://www.catheterprecision.com/'
        },
        { url: require('../assets/presenters/smithm.png'),
          name: 'Smith Micro Software Inc', class: 'smithmicro',
          market: 'NASDAQ', ticker: 'SMSI',
          path: 'https://www.smithmicro.com/'
        },
        { url: require('../assets/presenters/emmis.png'),
          name: 'Emmis Capital II', class: 'tcbiopharm',
          market: 'Private', ticker: '',
          path: 'https://emmiscap.com/'
        },
        { url: require('../assets/presenters/oncocyte.png'),
          name: 'OncoCyte Corp', class: 'tcbiopharm',
          market: 'NASDAQ', ticker: 'OCX',
          path: 'https://oncocyte.com/'
        },
        { url: require('../assets/presenters/promis.png'),
          name: 'Promis Neurosciences', class: 'promis',
          market: 'NASDAQ', ticker: 'PMN',
          path: 'https://www.promisneurosciences.com/'
        },
        { url: require('../assets/presenters/hg.png'),
          name: 'Heritage Global Inc', class: 'heritage',
          market: 'NASDAQ', ticker: 'HGBL',
          path: 'https://hginc.com/'
        },
        { url: require('../assets/presenters/adamas.png'),
          name: 'Adamas One Corp', class: 'adamas',
          market: 'NASDAQ', ticker: 'JEWL',
          path: 'https://www.adamasone.com/'
        },
        { url: require('../assets/presenters/naya.png'),
          name: 'NAYA Biosciences', class: 'naya',
          market: 'Private', ticker: '',
          path: 'https://www.nayabiosciences.com/'
        },
        { url: require('../assets/presenters/gemxx.png'),
          name: 'GEMXX Corp', class: 'gemxx',
          market: 'OTCMKTS', ticker: 'GEMZ',
          path: 'https://www.gemxx.com/'
        },
        { url: require('../assets/presenters/canfite.png'),
          name: 'Can-Fite BioPharma, Ltd', class: 'canfite',
          market: 'NASDAQ', ticker: 'LSTA',
          path: 'https://www.canfite.com/'
        },
        { url: require('../assets/presenters/evtv.png'),
          name: 'Envirotech Vehicles Inc', class: 'evtv',
          market: 'NASDAQ', ticker: 'EVTV',
          path: 'https://evtvusa.com/'
        },
        { url: require('../assets/presenters/c6.png'),
          name: 'C6 Capital', class: 'c6',
          market: 'Private', ticker: '',
          path: 'https://www.c6capllc.com/'
        },
        { url: require('../assets/presenters/soligenix.png'),
          name: 'Soligenix, Inc. Common Stock', class: 'soligenix',
          market: 'NASDAQ', ticker: 'SNGX',
          path: 'https://www.soligenix.com/'
        },
        { url: require('../assets/presenters/gpo.png'),
          name: 'GPO Plus Inc', class: 'gpo',
          market: 'OTCMKTS', ticker: 'GPOX',
          path: 'https://gpoplus.com/'
        },
        { url: require('../assets/presenters/athira.png'),
          name: 'Athira Pharma Inc', class: 'athira',
          market: 'NASDAQ', ticker: 'ATHA',
          path: 'https://www.athira.com/'
        },
        { url: require('../assets/presenters/upi.png'),
          name: 'Unigen Power Inc', class: 'unigen',
          market: 'Private', ticker: '',
          path: 'https://unigenpower.com/'
        },
        { url: require('../assets/presenters/seanet.png'),
          name: 'SeaNet Yachts', class: 'seanet',
          market: 'Private', ticker: '',
          path: 'https://seanetco.com/'
        },
        { url: require('../assets/presenters/energyfinders.png'),
          name: 'Energy Finders Inc', class: 'energyf',
          market: 'OTCMKTS', ticker: 'EGYF',
          path: 'https://energyfinders.us/'
        },
        { url: require('../assets/presenters/twohand.png'),
          name: 'Two Hands Corp', class: 'twohand',
          market: 'OTCMKTS', ticker: 'TWOH',
          path: 'https://twohandsgroup.com/'
        },
        { url: require('../assets/presenters/cocotaps.png'),
          name: 'Coco Taps', class: 'cocotaps',
          market: 'Private', ticker: '',
          path: 'https://www.cocotaps.com/'
        },
        { url: require('../assets/presenters/mbq.png'),
          name: 'MBQ Pharma', class: 'mbq',
          market: 'Private', ticker: '',
          path: 'https://mbqpharma.com/'
        },
        { url: require('../assets/presenters/rrbit.png'),
          name: 'RRBitcoins', class: 'rrbitcoins',
          market: 'Private', ticker: '',
          path: 'https://rrbitcoins.com/'
        },
        { url: require('../assets/presenters/pv.png'),
          name: 'Persist Ventures', class: 'persist',
          market: 'Private', ticker: '',
          path: 'https://persistventures.com/'
        },
        { url: require('../assets/sponsors/impeller.png'),
          name: 'Impeller', class: 'impeller',
          market: 'Private', ticker: '',
          path: 'https://impeller.investpr.org/offerings'
        },
        { url: require('../assets/presenters/abartys.png'),
          name: 'AbartysHealth', class: 'abartys',
          market: 'Private', ticker: '',
          path: 'https://www.abartyshealth.com/'
        },
        { url: require('../assets/presenters/medici.png'),
          name: 'Medici Bank', class: 'medicibank',
          market: 'Private', ticker: '',
          path: 'https://medici.bank/us/about'
        },
        { url: require('../assets/presenters/jesusc.png'),
          name: 'Jesus Coin', class: 'jesuscoin',
          market: 'Private', ticker: '',
          path: 'https://www.jesuscoin.xyz/'
        },
        { url: require('../assets/presenters/celerops.png'),
          name: 'CelerOps', class: 'celerops',
          market: 'Private', ticker: '',
          path: 'https://www.celerops.com/'
        },
        { url: require('../assets/presenters/lightning.png'),
          name: 'LightningWorks', class: 'lightning',
          market: 'Private', ticker: '',
          path: 'https://lightningworks.io/'
        },
        { url: require('../assets/presenters/redatlas.png'),
          name: 'RED Atlas', class: 'redatlas',
          market: 'Private', ticker: '',
          path: 'https://app.atlas.red/'
        },
        { url: require('../assets/presenters/ederra.png'),
          name: 'Ederra', class: 'ederra',
          market: 'Private', ticker: '',
          path: 'https://www.instagram.com/delcampofarmers/'
        },
        { url: require('../assets/presenters/soar.png'),
          name: 'Soar.com', class: 'soar',
          market: 'Private', ticker: '',
          path: 'https://try.soar.com/'
        },
        { url: require('../assets/presenters/estatex.png'),
          name: 'EstateX', class: 'estatex',
          market: 'Private', ticker: '',
          path: 'https://www.estatex.eu/'
        },
      ],
    };
  },
};
</script>