<template>
  <Menu buttonText="Event Registration" />
  <main id="entertainment">
    <hero />
    <carousel />
    <events />
  </main>
</template>

<style lang="scss" scoped>
  @import '../styles/pages/home.scss';
</style>

<script>
import Menu from '@/components/menu';
import hero from '@/components/entertainment/ehero.vue';
import carousel from '@/components/entertainment/carousel.vue';
import events from '@/components/entertainment/events.vue';

export default {
  name: 'Entertainment',
  data() {
    return {
    };
  },
  components: {
    Menu,
    hero,
    carousel,
    events
  },
};
</script>
