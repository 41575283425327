<template>
  <section class="nightlife">
    <div class="nightlife__header container">
      <h1 class="nightlife__title">
        San Juan's Nightlife Begins Here
      </h1>
      <p class="nightlife__description">
        Experience a night with more life at Condado Vanderbilt's stylish array of exclusive bars and lounges.
        Whether you'd like to drink in ocean views with your libation, or sink into a leather chair with
        a premium cigar in hand, a memorable evening of relaxation and pleasure awaits.
      </p>
    </div>
    <div class="nightlife__content container">
      <div class="nightlife__content--column">
        <img src="@/assets/venue/veritas.png" alt="Veritas Bar">
        <h1 class="nightlife__title">
          Veritas Wine & Cognac Bar
        </h1>
        <p class="nightlife__description">
          Combining some of life's most enjoyable delights — vintage wine, signature cocktails and
          irresistible beats – Veritas is the perfect place to kick back with friends.
        </p>
        <a href="https://www.condadovanderbilt.com/dine/veritas-wine-and-cognac-bar"
            target="_blank" class="nightlife__btn btn">
          <button class="button">Learn More</button>
        </a>
      </div>
      <div class="nightlife__content--column">
        <img src="@/assets/venue/vclounge.png" alt="VC Lounge">
        <h1 class="nightlife__title">
          VC Lounge
        </h1>
        <p class="nightlife__description">
          Beckoning you with warm breezes from its oceanfront terrace, VC Lounge is the epicenter of San Juan nightlife,
          with a grand – yet curiously intimate – vibe, perfect for chilling seaside, by day or at night.
        </p>
        <a href="https://www.condadovanderbilt.com/dine/vc-lounge"
            target="_blank" class="nightlife__btn btn">
          <button class="button">Learn More</button>
        </a>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'Nightlife',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/styles/components/venue/nightlife.scss";
</style>