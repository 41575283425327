<template>
  <div id="app">
    <router-view/>
    <Footer />
  </div>
</template>

<style lang="scss">
  @import '@/styles/style.scss';
</style>

<script>
import Footer from '@/components/footer';
import scrollingActions from '@/util/scrollingActions';

export default {
  name: 'app',
  components: {
    Footer,
  },
  methods: {

  },
  async mounted() {
    scrollingActions();
    window.onload = () => { scrollingActions(); };
    window.onscroll = () => { scrollingActions(); };
  },
};
</script>
