<template>
  <Menu buttonText="Event Registration" />
  <main id="venue">
    <welcome />
    <stay />
    <views />
    <services />
    <culinary />
    <dining />
    <nightlife />
    <experience />
    <vcta />
  </main>
</template>

<style lang="scss" scoped>
  @import '../styles/pages/home.scss';
</style>

<script>
import Menu from '@/components/menu';
import welcome from '@/components/venue/welcome.vue';
import stay from '@/components/venue/stay.vue';
import views from '@/components/venue/views.vue';
import services from '@/components/venue/services.vue';
import culinary from '@/components/venue/culinary.vue';
import dining from '@/components/venue/dining.vue';
import nightlife from '@/components/venue/nightlife.vue';
import experience from '@/components/venue/experience.vue';
import vcta from '@/components/venue/vcta.vue';

export default {
  name: 'Venue',
  data() {
    return {
    };
  },
  components: {
    Menu,
    welcome,
    stay,
    views,
    services,
    culinary,
    dining,
    nightlife,
    experience,
    vcta
},
};
</script>
